<template>
  <div>
    <div class="businessDetail_bg">
      <img src="../../static/image/business_bg.png" />
    </div>
    <div class="businessDetail_title">
      <div>中峪商贸 ></div>
      <div>商品详情</div>
    </div>
    <div class="businessDetail_line"></div>
    <div class="businessDetail_content">
      <div class="businessDetail_content_detail">
        <div class="businessDetail_content_detail_head">
          <img
            v-if="info.newsCover"
            :src="info.newsCover"
            width="190px"
          />

          <div class="businessDetail_content_detail_head_title">
            <div class="businessDetail_content_detail_head_title_name">
              {{ info.title }}
            </div>
            <div class="businessDetail_content_detail_head_title_time">
              上市时间：{{ info.year }}.{{ info.day }}
            </div>
          </div>
        </div>
        <div class="businessDetail_content_detail_tips">
          宝贝详情
        </div>
        <div class="businessDetail_content_detail_line"></div>
        <div
          class="businessDetail_content_detail_img"
          v-html="info.newsContent"
        ></div>
      </div>
      <div class="businessDetail_content_list">
        <div class="businessDetail_content_list_name">推荐爆款</div>
        <div class="businessDetail_content_list_content">
          <div
            class="businessDetail_content_list_content_item"
            v-for="item in businessList"
            @click="toDetails(item)"
            :key="item.id"
          >
            <img
            :src="item.newsCover"
            width="88px"
          />
            <div class="businessDetail_content_list_content_item_name">
              <div class="businessDetail_content_list_content_item_title">
                {{ item.title }}
              </div>
              <div class="businessDetail_content_list_content_item_time">
                上市时间：{{ item.year }}.{{ item.day }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "businessDetail",
  data() {
    return {
      info: {},
      businessList: [],
    };
  },
  mounted() {
    window.scroll(0, 0);
    const id = this.$route.query.id;
    this.getDetail(id);
    this.getList();
  },
  methods: {
    getDetail(id) {
      //获取独立的数据
      axios
        .get(`https://admin.zoneyu.cn/cms/api/app/news/${id}`)
        .then((res) => {
          this.info = res.data.data;
          const updateTime = new Date(this.info.publishTime);
          this.info.day =
            updateTime.getDate() > 9
              ? updateTime.getDate()
              : "0" + updateTime.getDate();
          this.info.year =
            updateTime.getFullYear() +
            "." +
            (updateTime.getMonth() + 1 > 9
              ? updateTime.getMonth() + 1
              : "0" + (updateTime.getMonth() + 1));
        });
    },
    getList() {
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?status=PUBLISHED&current=1&size=5&websiteCategoryId=4`, // 17
          {
            headers: {
              "Admin-Authorization": localStorage.getItem("token"),
            },
          }
        )
        .then(async (res) => {
          const content = res.data.data.list;
          content.forEach((item) => {
            const updateTime = new Date(item.publishTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          this.businessList = content;
        });
    },
    toDetails(item){
      this.getDetail(item.newsId);
    }
  },
};
</script>
<style scoped lang="scss">
.businessDetail_bg {
  img {
    width: 100%;
  }
}
.businessDetail_title {
  font-size: 16px;
  font-weight: 500;
  color: #abaeb0;
  line-height: 22px;
  display: flex;
  width: 1280px;
  margin: 27px auto;
}
.businessDetail_line {
  width: 100%;
  height: 1px;
  background: #f7f7f7;
  margin-bottom: 40px;
}
.businessDetail_content {
  width: 1280px;
  margin: 0 auto 30px auto;
  display: flex;
  .businessDetail_content_detail {
    width: 900px;
    .businessDetail_content_detail_head {
      display: flex;
      .businessDetail_content_detail_head_title_name {
        font-size: 22px;
        font-weight: bold;
        color: #1d1b19;
        line-height: 30px;
        letter-spacing: 1px;
        margin-left: 50px;
      }
      .businessDetail_content_detail_head_title_time {
        width: 160px;
        height: 32px;
        background: #9e1307;
        font-size: 14px;
        font-weight: 400;
        color: #f7f7f7;
        line-height: 32px;
        text-align: center;
        margin-left: 50px;
        margin-top: 10px;
      }
    }
    .businessDetail_content_detail_tips {
      font-size: 22px;
      font-weight: bold;
      color: #1d1b19;
      line-height: 30px;
      letter-spacing: 1px;
      margin-top: 32px;
    }
    .businessDetail_content_detail_line {
      width: 100%;
      height: 4px;
      background: #9e1307;
      margin-top: 20px;
    }
    /deep/ .businessDetail_content_detail_img {
      margin-top: 28px;
      img {
        width: 100%;
      }
    }
  }
  .businessDetail_content_list {
    width: 316px;
    margin-left: 64px;
    .businessDetail_content_list_name {
      font-size: 16px;
      font-weight: bold;
      color: #9e1307;
      line-height: 22px;
      text-align: center;
      margin-bottom: 15px;
    }
    .businessDetail_content_list_content_item {
      padding: 40px 0 24px 0;
      border-top: 1px solid #e5e5e5;
      display: flex;
      .businessDetail_content_list_content_item_name {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #1d1b19;
        line-height: 20px;
        .businessDetail_content_list_content_item_title {
          width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .businessDetail_content_list_content_item_time {
          font-size: 12px;
          font-weight: 400;
          color: #abaeb0;
          line-height: 17px;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
